import React, { useState } from 'react';
import Modal from 'react-modal';

// Modal.setAppElement('#root');

const ShowMessageModel = ({ isOpen, onClose, text}) => {
  const [reason, setReason] = useState('');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Unlist Property Reason"
      style={{
        overlay:{
            zIndex:5,
            // backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          maxWidth:500,
          transform: 'translate(-50%, -50%)',
          minWidth:'30%',
          
        },
      }}
    >
    <div className=''>
      <h4 className='text-center'>Unlist Property Reason</h4>
      <p className='mt-4'>
          {text}
      </p>
      <div className='text-center tw-mt-4'>
        <button className='btn btn-primary tw-py-2 ' onClick={onClose}>Close</button>
      </div>
      </div>
    </Modal>
  );
};

export default ShowMessageModel;
