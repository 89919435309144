import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { throttle } from "lodash";
import { Link, useHistory } from "react-router-dom";
import CustomModal from "../ReactModal";
import Navbar from "../Navbar";
import TopBar from "../topbar";
import { propertyService, feeService } from "../../_services";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "../../layout/footer";
import constant from "../../_config/constant";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ReservationList(props) {
  const history = useHistory();

  const [requesting, setRequesting] = useState(true);
  const [cancelReservationId, setCancelReservationId] = useState(null);
  const [crewmatesFlatPenalty, setCrewmatesFlatPenalty] = useState(0);
  const [propertyList, setPropertyList] = useState([]);
  const [filters, setFilters] = useState({});
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showDateSelpopup, setShowDateSelpopup] = useState(false);
  const [isCancelByAdmin, setIsCancelByAdmin] = useState(true);
  const [cancelDate, setCancelDate] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [isCancelAvailable, setIsCancelAvailable] = useState(false);

  var limit = 10;

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  const toggleCancelModal = (id = null, booking = []) => {
    if (!!booking.id) {
      setBookingData(booking);

      const checkInDate = new Date(booking.checkInDate)
        .toISOString()
        .split("T")[0];
      const checkInDateFormat = moment(checkInDate).valueOf();
      const currentDateFormat = moment().valueOf();
      console.log("moment()", moment());
      console.log("checkInDate", moment(checkInDate));
      if (currentDateFormat > checkInDateFormat) {
        setIsCancelAvailable(true);
        console.log("checkInDateFormat", checkInDateFormat);
      } else {
        console.log("checkInDateFormat", checkInDateFormat);
        setIsCancelAvailable(false);
      }
    }
    if (!id) {
      setCancelDate(booking.cancelDate);
    }
    setCancelReservationId((e) => (e !== null ? null : id));
  };

  const handleSearch = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const throtteledSearch = useCallback(throttle(handleSearch, 900), []);

  const getBedCount = (Arrangements) => {
    if (!Arrangements || !Arrangements.length) return 0;
    return Arrangements.reduce(function (acc, obj) {
      return acc + parseInt(obj.numberOfBeds);
    }, 0);
  };

  function renderActionChip(status, id, booking) {
    switch (status) {
      case "Pending": {
        return (
          <>
            <li
              onClick={() => handleBooking("Accepted", id)}
              className="tw-cursor-pointer tw-p-2 tw-px-4 p-2 tw-text-xs tw-rounded-md tw-bg-primary tw-text-white tw-text-center"
            >
              Accept
            </li>
            <li
              onClick={() => handleBooking("Rejected", id)}
              className="tw-cursor-pointer tw-p-2 tw-px-4 p-2 tw-text-xs tw-rounded-md tw-bg-primary tw-text-white tw-text-center"
            >
              Reject
            </li>
          </>
        );
      }
      case "Accepted": {
        return (
          <>
            <li
              onClick={() => toggleCancelModal(id, booking)}
              className="tw-cursor-pointer tw-p-2 tw-px-4 p-2 tw-text-xs tw-rounded-md tw-bg-primary tw-text-white tw-text-center"
            >
              Cancel
            </li>
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  }

  const pageChange = (page) => {
    setPage(page);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const handleFliters = (key, value) => {
    if (value) {
      setFilters((e) => {
        const newFilter = { ...e, [key]: value };
        return newFilter;
      });
    } else {
      setFilters((e) => {
        const newFilter = { ...e };
        delete newFilter[key];
        return newFilter;
      });
    }
  };

  function handleFilterReset() {
    history.go(0);
  }

  const handleBooking = async (status, id) => {
    try {
      const res = await propertyService.updateBookingStatus({
        id: id,
        status,
      });

      if (res.status) {
        toast.success(res.message);
        setRefreshKey((e) => e + 1);
      } else toast.error(res.message);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const adminCancelBookingBeforeCheckIn = async (id) => {
    try {
      const res = await propertyService.adminCancelBookingBeforeCheckIn(id);

      if (res.status) {
        toast.success(res.message);
        setRefreshKey((e) => e + 1);
        setCancelReservationId(null);
      } else toast.error(res.message);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const cancelBookingByAdmin = async (id) => {
    console.log("Cancel booking by admin");
    try {
      const res = await propertyService.cancelBookingByHost(id);

      if (res.status) {
        toast.success(res.message);
        setRefreshKey((e) => e + 1);
        setCancelReservationId(null);
      } else toast.error(res.message);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const cancelBookingFutureDate = async (id) => {
    try {
      const res = await propertyService.AdminCancelBookingByDate(
        id,
        cancelDate,
        isCancelByAdmin
      );

      if (res.status) {
        toast.success(res.message);
        setRefreshKey((e) => e + 1);
        setCancelReservationId(null);
        setShowDateSelpopup(false);
      } else toast.error(res.message);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const { data } = await feeService.getFee();

      console.log({ data });

      if (data.length) {
        setCrewmatesFlatPenalty(
          parseFloat(data[data.length - 1].crewmatesFlatPenaltyFee) || 0
        );
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    setRequesting(true);
    propertyService.bookinglist({ page, limit: 10, ...filters, search }).then(
      (objS) => {
        setList(objS.data.docs);
        setTotal(objS.data.total);
        setRequesting(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        console.log(error);
        setRequesting(false);
      }
    );
  }, [refreshKey, filters]);

  useEffect(() => {
    propertyService.allPropertylist().then(
      (objS) => {
        setPropertyList(objS.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  // function for cancellation date selection

  const handleCancelDatePopup = (status, isAdmin) => {
    setShowDateSelpopup(status);
    if (status) {
      setCancelDate("");
      setIsCancelByAdmin(isAdmin);
    }
  };

  const handleCancelDate = (cancelDateVal) => {
    setCancelDate(cancelDateVal);
  };

  const getNoticeText = (data) => {
    // (Notice Applied by hostname/Crewmates)
    switch (data.noticeBy) {
      case "Host":
        return `(Notice Applied by ${data.hostId.name})`;
      case "Guest":
        return `(Notice Applied by ${data.userId.name})`;
      case "Admin":
        return `(Notice Applied by Crewmates)`;
      default:
        return "";
    }
  };

  const showStausMessagesWithNotice = (data) => {
    if (data.isNotice) {
      switch (data.status) {
        case "Accepted":
          return `Accepted ${getNoticeText(data)}`;
        case "Cancel_Scheduled":
          return `Cancel Scheduled ${getNoticeText(data)}`;
        default:
          return data.status;
      }
    } else {
      switch (data.status) {
        case "Accepted":
          return `Accepted`;
        case "Cancel_Scheduled":
          return `Cancel Scheduled`;
        case 'Cancelled':
          if (data.cancelledByHost) {
            return 'Cancelled by Host'
          } else if (data.isCancelByAdmin) {
            return 'Cancelled by Crewmates'
          } else if(data.bookingCancelledBy){
            return `Cancelled by ${data.bookingCancelledBy}`
          }else{
            return data.status
          }
        case 'Rejected':
          if (data.isExpired) {
            return 'Expired'
          } else if(data.bookingCancelledBy){
            return `Rejected by ${data.bookingCancelledBy}`
          }else{
            return data.status
          }

        default:
          return data.status;
      }
    }
  };

  const exportReservations = async () => {
    await propertyService.exportReservations({ ...filters, search });
  };

  const openMessages = (booking) => {
    const propertyData = booking.propertyId;
    const hostData = booking.hostId;
    const userData = booking.userId;
      history.push({
        pathname: '/message',
        state: {
            channelId: `${propertyData._id}-${userData.id}-${hostData.id}-${booking.bookingId}`,
            friendlyName: `${propertyData._id}-${userData.id}-${hostData.id}-${booking.bookingId}`,
            property: {
                name: propertyData.listingName,
                id: propertyData._id,
                host: hostData.name,
                user: userData.name,
                picture: propertyData.images[0],
                reservationId: booking.bookingId
            },
            user: {
                id: userData.id,
                name: userData.name,
                profile: userData.avatar,
                email: userData.personalEmail,
            },
            host: {
                id: hostData.id,
                name: hostData.name,
                profile: hostData.avatar,
                email: hostData.personalEmail,
            },
        },
    })
  }

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar
        user={decoded}
        showDownload={true}
        exportReservations={exportReservations}
        showSearch={true}
        handleSearch={(e) => {
          setSearch(e.target.value);
          throtteledSearch(e.target.value);
        }}
        search={search}
        title="Reservation List"
      />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h4 className="tw-text-xl tw-font-medium">Filter</h4>
              <div className="tw-grid tw-grid-cols-2  md:tw-grid-cols-6 tw-gap-2 tw-mb-4">
                <div>
                  <label className="text-label form-label">Check In</label>
                  <input
                    onChange={(e) => handleFliters("start", e.target.value)}
                    type="date"
                    name="checkInData"
                    className="form-control form-control-sm"
                    placeholder="Check In"
                  />
                </div>
                <div>
                  <label className="text-label form-label">Check Out</label>
                  <input
                    onChange={(e) => handleFliters("end", e.target.value)}
                    type="date"
                    name="checkOutData"
                    className="form-control form-control-sm"
                    placeholder="Check In"
                  />
                </div>
                {/* <div>
                  <label className="form-label">Rental Type</label>
                  <select
                    onChange={(e) => handleFliters("pt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Crashpad">Crashpad</option>
                    <option value="Entire Place">Entire Place</option>
                  </select>
                </div> */}
                <div>
                  <label className="form-label">Listing Name</label>
                  <select
                    onChange={(e) => handleFliters("ppt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">All</option>
                    {propertyList.map((curr) => (
                      <option value={curr.id} key={curr.id}>
                        {curr.listingName}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Bed Type</label>
                  <select
                    onChange={(e) => handleFliters("bt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Hot bed">Hot Bed</option>
                    <option value="Cold bed">Cold Bed</option>
                  </select>
                </div>
                <div>
                  <label className="form-label">Status</label>
                  <select
                    onChange={(e) => handleFliters("s", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Pending">Pending</option>
                    <option value="Accepted">Accepted</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Cancel_Scheduled">Cancel_Scheduled</option>
                  </select>
                </div>
                <div>
                  <label className="text-label form-label">Reset Filter</label>
                  <button
                    onClick={handleFilterReset}
                    className="btn-sm btn btn-primary form-control form-control-sm"
                  >
                    Reset
                  </button>
                </div>
              </div>
              {/* <div>
                <div className="tw-my-4">
                  <label className="form-label">Search</label>
                  <input type="text" name="" id="" className="form-control" />
                </div>
              </div> */}
              {requesting && (
                <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                  <div className="bg-white tw-w-max rounded shadow-md p-4">
                    <div className="loader"></div>
                  </div>
                </div>
              )}
              {cancelReservationId && (
                <CustomModal>
                  <div className="tw-bg-white tw-mx-auto tw-rounded-md tw-p-4">
                    <p className="tw-text-sm ">
                      In an effort to provide a consistent experience for crew,
                      there is a ${crewmatesFlatPenalty} host cancellation
                      penalty. As a reminder, service fees are calculated on the
                      original transaction and are non-refundable. Are you sure
                      you want to cancel the reservation?
                    </p>

                    <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2 py-3">
                      <button
                        onClick={toggleCancelModal}
                        className="btn btn-sm btn-secondary"
                      >
                        Back
                      </button>
                      <button
                        onClick={() =>
                          isCancelAvailable && bookingData.numberOfDay > 1
                            ? handleCancelDatePopup(true, false)
                            : // handleBooking("Cancelled", cancelReservationId)
                            adminCancelBookingBeforeCheckIn(
                              cancelReservationId
                            )
                        }
                        className="btn btn-sm btn-primary"
                      >
                        Cancel Booking
                      </button>
                      <button
                        onClick={() =>
                          isCancelAvailable && bookingData.numberOfDay > 1
                            ? // handleBooking("Cancelled", cancelReservationId)
                            handleCancelDatePopup(true, true)
                            : cancelBookingByAdmin(cancelReservationId)
                        }
                        className="btn btn-sm btn-primary"
                      >
                        Cancel (Without Penalty)
                      </button>
                    </div>
                  </div>
                </CustomModal>
              )}
              {showDateSelpopup && (
                <CustomModal>
                  <div
                    className="tw-bg-white tw-mx-auto tw-rounded-md tw-p-4"
                    style={{ width: "60%" }}
                  >
                    <div className="tw-flex tw-items-center">
                      <p className="tw-text-sm ">
                        Your booking will be canceled on:
                      </p>
                      <div className="tw-grid tw-grid-cols-3 mx-2">
                        <input
                          onChange={(e) => handleCancelDate(e.target.value)}
                          type="date"
                          min={
                            !!bookingData.checkInDate
                              ? new Date(bookingData.checkInDate)
                                .toISOString()
                                .split("T")[0]
                              : new Date().toISOString().split("T")[0]
                          }
                          max={
                            !!bookingData.checkOutDate
                              ? new Date(
                                new Date(bookingData.checkOutDate).setDate(
                                  new Date(
                                    bookingData.checkOutDate
                                  ).getDate() - 1
                                )
                              )
                                .toISOString()
                                .split("T")[0]
                              : moment()
                                .clone()
                                .add(1, "M")
                                .startOf("month")
                                .format("DD-MM-YYYY")
                          }
                          /*                          max={!!bookingData.checkOutDate ? new Date(bookingData.checkOutDate).toISOString().split('T')[0] : moment().clone().add(1 , "M").startOf('month').format('YYYY-MM-DD')} */
                          name="cancelDate"
                          onKeyDown={(e) => e.preventDefault()}
                          className="form-control form-control-sm h-auto"
                          placeholder="dd-mm-yyyy"
                        />
                      </div>
                    </div>

                    <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2 py-3">
                      <button
                        onClick={() => handleCancelDatePopup(false, true)}
                        className="btn btn-sm btn-secondary"
                      >
                        Back
                      </button>
                      <button
                        disabled={!cancelDate}
                        onClick={() =>
                          // handleBooking("Cancelled", cancelReservationId)
                          // isCancelByAdmin ?
                          //   cancelBookingByAdmin(cancelReservationId) :

                          cancelBookingFutureDate(cancelReservationId)
                        }
                        className="btn btn-sm btn-primary"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </CustomModal>
              )}
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Status</th>
                          <th>Reservation Id</th>
                          <th>Confirmation Code</th>
                          <th>Host Name</th>
                          <th>Guest Name</th>
                          <th>Listing Name</th>
                          <th>Booking Type</th>
                          {/* <th>Room Name</th> */}
                          <th>Total Amount</th>
                          <th>Check In</th>
                          <th>Check Out</th>
                          <th>Weekly Payout Dates</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Payout Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((booking) => (
                          <tr key={booking.id}>
                            <td className="tw-flex tw-flex-col tw-space-y-2 list-btn  ">
                              {renderActionChip(
                                booking.status,
                                booking.id,
                                booking
                              )}
                              <Link to={`/reservation/${booking.id}`}>
                                <li className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-rounded-md tw-bg-primary tw-text-white">
                                  Details
                                </li>
                              </Link>
                              <li
                                onClick={() => openMessages(booking)}
                                className="tw-cursor-pointer tw-text-center tw-rounded-md  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
                              >
                                Message
                              </li>
                            </td>
                            <td className="data-col">
                              {/* <span className="fs-16">{booking.status}</span> */}
                              <span className="fs-16">
                                {showStausMessagesWithNotice(booking)}
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                {booking.bookingId || "--"}{" "}
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                {booking.confirmationCode || "--"}{" "}
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                {booking.hostId.name}{" "}
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                {booking.userId.name}{" "}
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                {" "}
                                {booking.propertyId.listingName}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {" "}
                                {booking.propertyId.bookingType === "Flexible"
                                  ? `Flexible ${booking.bookingType}`
                                  : booking.bookingType}{" "}
                              </span>
                            </td>
                            {/* <td>
                              <span className="fs-16">
                                {booking.selectedRoom}
                              </span>
                            </td> */}
                            <td className="data-col">
                              <span className="fs-16">
                                {(
                                  booking.wholeBookingTotal || 0
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                                {/* {(booking.totalPrice || 0).toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )} */}
                              </span>
                            </td>

                            <td className="data-col">
                              <span className="fs-16">
                                {moment(
                                  booking.checkInDate.split("T")[0]
                                ).format("MMMM Do, YYYY")}
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                {booking.checkOutDate
                                  ? moment(
                                    booking.checkOutDate.split("T")[0]
                                  ).format("MMMM Do, YYYY")
                                  : "--"}
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">{"--"}</span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                <Moment format="MMMM Do, YYYY">
                                  {booking.createdAt}
                                </Moment>
                              </span>
                            </td>
                            <td className="data-col">
                              <span className="fs-16">
                                <Moment format="MMMM Do, YYYY">
                                  {booking.updatedAt}
                                </Moment>
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">{"--"}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
